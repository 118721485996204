<template>
  <div
    class="card mb-3 main-card"
    :style="this.$vuetify.theme.dark ? 'background-color: #424242;' : ''"
  >
    <div
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header-tab card-header"
    >
      <div
        class="card-header-title font-size-lg text-capitalize font-weight-normal"
      >
        <font-awesome-icon
          :icon="['fal', 'chart-bar']"
          class="kassen pr-2 text-info"
          size="2x"
        />
        {{ $t("generic.lang_salesPerHour") }}
      </div>
    </div>

    <div class="card-body pa-0">
      <apexchart
        v-show="!loading && !this.$vuetify.breakpoint.smAndDown"
        height="350"
        type="line"
        :options="chartOptions"
        :series="series"
      ></apexchart>
      <apexchart
        v-show="!loading && this.$vuetify.breakpoint.smAndDown"
        height="450"
        :options="barChartOptions"
        :series="barChartSeries"
      ></apexchart>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChartBar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapState } from "vuex";

library.add(faChartBar);

var total = null;
var self = null;

export default {
  name: "SalesChart",

  components: {
    apexchart: VueApexCharts,
    FontAwesomeIcon,
  },

  computed: {
    ...mapState(["api"]),
    getMinMaxSeries() {
      let min = Math.min(...this.barChartSeries[0].data);
      let max = Math.max(...this.barChartSeries[1].data);
      let minMax = Math.max(max, Math.abs(min));
      minMax += 20;
      return minMax;
    },
  },
  data() {
    return {
      loading: true,
      data: [],
      series: [
        {
          name: this.$t("generic.lang_yesterday"),
          type: "area",
          data: [], //FILLED BY AXIOS CALL
        },
        {
          name: this.$t("generic.lang_today"),
          type: "line",
          data: [], //FILLED BY AXIOS CALL
        },
        {
          name: this.$t("generic.lang_customersYesterday"),
          type: "column",
          data: [], //FILLED BY AXIOS CALL
        },
        {
          name: this.$t("generic.lang_customersToday"),
          type: "column",
          data: [], //FILLED BY AXIOS CALL
        },
      ],
      barChartSeries: [
        {
          name: this.$t("generic.lang_yesterday"),
          data: [], //FILLED BY AXIOS CALL
        },
        {
          name: this.$t("generic.lang_today"),
          data: [], //FILLED BY AXIOS CALL
        },
      ],

      barChartOptions: {
        chart: {
          height: 700,
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: false,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        colors: ["#16aaff", "#d92550"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "95%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(Math.round(val));
          },
          style: {
            colors: ["#333"],
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        grid: {
          show: false,
        },
        yaxis: {
          tickAmount: 24,
          min: -100,
          max: 100,
          title: {
            text: this.$t("generic.lang_hours"),
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return Math.abs(val);
            },
          },
          y: {
            formatter: function (val) {
              return Math.abs(val);
            },
          },
        },
        xaxis: {
          title: {
            text: this.$t("generic.lang_sales"),
          },
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val));
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        labels: [],
      },
      chartOptions: {
        chart: {
          stacked: false,
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        stroke: {
          width: [0, 4, 0, 0],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        fill: {
          opacity: [0.25, 1, 0.5, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ], //FILLED BY AXIOS CALL
        markers: {
          size: 0,
        },
        xaxis: {
          title: {
            text: this.$t("generic.lang_statsTimeHour"),
          },

          type: "time",
        },
        yaxis: [
          {
            show: false,
            max: (max) => {
              max = Math.max(
                ...this.series[0]["data"],
                ...this.series[1]["data"]
              );
              return max;
            },
            forceNiceScale: true,
            decimalsInFloat: 0,
            // tickAmount: 5,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            labels: {
              formatter: (val) => {
                return this.$options.filters.currency(val);
              },
            },
            title: {
              text: this.$t("accounting.lang_turnover"),
            },
          },
          {
            max: (max) => {
              max = Math.max(
                ...this.series[0]["data"],
                ...this.series[1]["data"]
              );
              return max;
            },
            forceNiceScale: true,
            decimalsInFloat: 0,
            // tickAmount: 5,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: this.$t("accounting.lang_turnover"),
            },
            labels: {
              formatter: (val) => {
                return this.$options.filters.currency(val);
              },
            },
          },
          {
            opposite: true,
            max: (max) => {
              max = Math.max(
                ...this.series[2]["data"],
                ...this.series[3]["data"]
              );
              return max;
            },
            forceNiceScale: true,
            decimalsInFloat: 0,
            // tickAmount: 5,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: this.$t("customers.lang_noSalesCusts"),
            },
          },
          {
            show: false,
            opposite: true,
            max: (max) => {
              max = Math.max(
                ...this.series[2]["data"],
                ...this.series[3]["data"]
              );
              return max;
            },
            forceNiceScale: true,
            decimalsInFloat: 0,
            // tickAmount: 5,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: this.$t("customers.lang_noSalesCusts"),
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: [
            {
              formatter: (y) => {
                if (typeof y !== "undefined") {
                  return this.$options.filters.currency(y);
                }
                return y;
              },
            },
            {
              formatter: (y) => {
                if (typeof y !== "undefined") {
                  return this.$options.filters.currency(y);
                }
                return y;
              },
            },
            {
              formatter: function (y) {
                if (y === 1) return y + " " + self.$t("generic.lang_customer");

                return y + " " + self.$t("customers.lang_customers");
              },
            },
            {
              formatter: function (y) {
                if (y === 1) return y + " " + self.$t("generic.lang_customer");

                return y + " " + self.$t("customers.lang_customers");
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    total = this;
    self = this;

    //this.barChartOptions.labels=[];

    for (let hour = 0; hour < 24; hour++) {
      this.chartOptions.labels.push(hour + ":00");
      //this.chartOptions.theme.mode = this.$vuetify.theme.dark ? 'dark' : 'light';
      this.barChartOptions.labels.push(hour + " ");
      //this.barChartOptions.theme.mode = this.$vuetify.theme.dark ? 'dark' : 'light';
    }

    this.getSalesData();
  },

  methods: {
    getSalesData() {
      this.loading = true;

      this.axios
        .post("get/dashboard/salesChart/", {
          cashierID: 1,
        })
        .then((res) => {
          if (res.data.success) {
            //YESTERDAY
            let yesterdayData = res.data.yesterday;

            //SET TOTAL DATA
            this.series[0].data = yesterdayData.map((hour) => {
              return parseFloat(hour.totalBrutto);
            });
            this.barChartSeries[0].data = yesterdayData.map((hour) => {
              return parseFloat(hour.totalBrutto) * -1;
            });
            //SET CUSTOMER DATA
            this.series[2].data = yesterdayData.map((hour) => {
              return hour.totalCustomers;
            });

            //TODAY
            let todayData = res.data.today;

            //SET TOTAL DATA
            this.series[1].data = todayData.map((hour) => {
              return parseFloat(hour.totalBrutto);
            });
            this.barChartSeries[1].data = this.series[1].data;
            //SET CUSTOMER DATA
            this.series[3].data = todayData.map((hour) => {
              return hour.totalCustomers;
            });
            this.loading = false;

            this.barChartOptions = {
              yaxis: {
                type: "time",
                min: -this.getMinMaxSeries,
                max: this.getMinMaxSeries,
                labels: {
                  show: true,
                },
                title: {
                  text: this.$t("generic.lang_hours"),
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: true,
                },
              },
            };
          }
        });
    },
  },
};
</script>
<style>
.apexcharts-toolbar {
  z-index: 2 !important;
}
</style>
